import { GetServerSideProps, GetStaticProps, NextPage } from "next";
import React from "react";
import { Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton";
import _ from "lodash";
import { SnackbarProvider } from "notistack";
import Script from "next/script";
import PreviewCardV2 from "../components/preview-card2";
import { DigitalCard, DigitalCardField } from "../services/apiService/responseModels";
import ErrorPanel from "../components/error-panel";
import { getAllCardFieldCategories, getAllCardPathItems, getCardByNameOrId } from "../services/apiService";
import parseCardToVcard from "../utils/parseCardToVcard";

type ViewerProps = {
  cardName: string;
  hasError: boolean;
  card: DigitalCard | null;
  fields: DigitalCardField[];
  vCard:vCardType | null;
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const GridStyled = styled(Grid)(
  ({ theme }) => `

background: #ADA996;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
 min-height: 100vh;

  `
);

const Viewer: NextPage<ViewerProps> = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  if (props.hasError) {
    return (
      <div style={{ margin: 10 }}>
        <ErrorPanel
          title="Not found"
                message={"No details to dispaly for: " + props.cardName}
        />
      </div>
    );
  }

  const getBgStyle = () => {
    if (props.card?.theme === "black") {
      return {
        background:
          "radial-gradient(circle at -4% -12.9%, rgb(74, 98, 110) 0.3%, rgb(30, 33, 48) 90.2%)",
      };
    } else if (props.card?.theme === "blue") {
      return {
        background:
          "radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(111,111,230,1) 100%, rgba(0,212,255,1) 100%)",
      };
    }
    return {};
  };
  return (
    <SnackbarProvider
      maxSnack={6}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <GridStyled container sx={getBgStyle()}>
        <Grid item display={{ xs: "none", md: "block" }} md={4}></Grid>
        <Grid
          item
          xs={12}
          margin={{ xs: 1, md: 0 }}
          marginTop={{ xs: 1, md: 4 }}
          md={4}
        >
          {props.card && (
            <PreviewCardV2 card={props.card} fieldsList={props.fields} vCard={props.vCard}/>
          )}
        </Grid>
        <Grid item display={{ xs: "none", md: "block" }} md={4}></Grid>
      </GridStyled>
    </SnackbarProvider>
  );
};

export default Viewer;

export type vCardType = {
  name:string;
  data:string;
}

export const getStaticProps: GetStaticProps = async (context) => {
  const cardName = context.params?.cardId as string || "0";
  console.log('static-props : ',context.params);
  let hasError = false;
  let card: DigitalCard | null = null;
  let allFields: DigitalCardField[] = [];
  let vCard:vCardType | null=null;

  try {
      const res1 = await getCardByNameOrId(cardName);
    card = res1.data;

    const res2 = await getAllCardFieldCategories();
    const fieldCategories = res2.data;

    const idsInCard = card.fields.map((o) => o.fieldId);

    fieldCategories.forEach((cat) => {
      const idsInCat = cat.digitalCardFields.map((o) => o.id);
      const matches = _.intersection(idsInCard, idsInCat);
      if (matches.length) {
        allFields = allFields.concat(
          cat.digitalCardFields.filter((o) => matches.includes(o.id))
        );
      }
    });
   
    const vCardRef=await parseCardToVcard(card,allFields);
    if(vCardRef){
      vCard ={
        name:vCardRef.name,
        data:vCardRef.vCard.getFormattedString()
        .replace(/SOCIALPROFILE;CHARSET=UTF-8;/gm, "SOCIALPROFILE;")
      }
  }
    
  } catch (ex) {
    console.log(ex);
    hasError = true;
  }

  const result: ViewerProps = {
    cardName: cardName,
    hasError: hasError,
    card: card,
    fields: allFields,
    vCard:vCard
  };
  return {
    props: result,
    revalidate:180
  };
};

export async function getStaticPaths() {
  const res = await getAllCardPathItems();
  const items = await res.data
 
  // Get the paths we want to pre-render based on posts
  const paths = items.map((o) => ({
    params: { cardId: o.name || o.id.toString() },
  }))
 
  // We'll pre-render only these paths at build time.
  // { fallback: 'blocking' } will server-render pages
  // on-demand if the path doesn't exist.
  return { paths, fallback: 'blocking' }
}
